@import '../../assets/styles/colors.scss';

.error {
    width: 300px;
    margin-top: 2rem;
}

.empty_pagy {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    h1 {
        font-size: 46px;
        color: red;
        font-weight: 600;
    }

    .link {
        color: $main;
        text-decoration: none;
        font-size: 24px;
    }
}