@import '../style.scss';

.planet-wrap {

    @extend %planetWrapp;

    .saturn {

        @extend %planet;
    
        transform: scale(1.5);
        
        & .planet {
        width: 400px;
        height: 400px;
        position: relative;
        z-index: 2;
        border-radius: 50%;
        transform: rotateZ(0deg);
            
            
            .shadow {
                position: absolute;
                top: 70px;
                left: -100px;
                z-index: 2;
                width: 606px;
                height: 246px;
                border-radius: 50%;
                background: 
                linear-gradient(85deg, black 17%, rgba(255, 255, 255, 0) 222%), 
                linear-gradient(137deg, black 19%, rgba(255, 255, 255, 0) 132%), 
                linear-gradient(45deg, black 27%, rgba(255, 255, 255, 0) 82%);
                transform: rotateX( 35deg ) rotateY(-27deg);
            
            }
    
    
    
            .rings {
              position: relative;
              top: -298px;
              left: -73px;
              z-index: 1;
              width: 139%;
              height: 244%;
    
              background: -moz-radial-gradient(center, ellipse cover,  rgba(24,19,25,0) 0%, rgba(53,52,51,0) 34%, rgba(55,54,52,1) 36%, rgba(56,55,53,1) 37%, rgba(68,67,66,1) 38%, rgba(56,55,53,1) 39%, rgba(68,67,66,1) 40%, rgba(56,55,53,1) 41%, rgba(87,77,76,1) 42%, rgba(87,77,76,1) 44%, rgba(113,110,103,1) 46%, rgba(113,110,103,1) 48%, rgba(113,98,93,1) 49%, rgba(113,98,93,1) 51%, rgba(122,115,105,1) 52%, rgba(113,98,93,1) 53%, rgba(113,98,93,1) 54%, rgba(122,115,105,1) 55%, rgba(106,99,89,1) 56%, rgba(106,99,89,1) 58%, rgba(79,76,76,0) 60%, rgba(65,64,70,1) 61%, rgba(65,64,70,1) 62%, rgba(90,85,89,1) 63%, rgba(78,74,73,1) 65%, rgba(78,73,74,1) 67%, rgba(78,73,74,0) 68%, rgba(78,73,75,1) 69%, rgba(78,73,75,1) 70%, rgba(78,73,76,0) 71%, rgba(77,72,76,0) 72%, rgba(24,19,25,0) 100%);
              background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(24,19,25,0)), color-stop(34%,rgba(53,52,51,0)), color-stop(36%,rgba(55,54,52,1)), color-stop(37%,rgba(56,55,53,1)), color-stop(38%,rgba(68,67,66,1)), color-stop(39%,rgba(56,55,53,1)), color-stop(40%,rgba(68,67,66,1)), color-stop(41%,rgba(56,55,53,1)), color-stop(42%,rgba(87,77,76,1)), color-stop(44%,rgba(87,77,76,1)), color-stop(46%,rgba(113,110,103,1)), color-stop(48%,rgba(113,110,103,1)), color-stop(49%,rgba(113,98,93,1)), color-stop(51%,rgba(113,98,93,1)), color-stop(52%,rgba(122,115,105,1)), color-stop(53%,rgba(113,98,93,1)), color-stop(54%,rgba(113,98,93,1)), color-stop(55%,rgba(122,115,105,1)), color-stop(56%,rgba(106,99,89,1)), color-stop(58%,rgba(106,99,89,1)), color-stop(60%,rgba(79,76,76,0)), color-stop(61%,rgba(65,64,70,1)), color-stop(62%,rgba(65,64,70,1)), color-stop(63%,rgba(90,85,89,1)), color-stop(65%,rgba(78,74,73,1)), color-stop(67%,rgba(78,73,74,1)), color-stop(68%,rgba(78,73,74,0)), color-stop(69%,rgba(78,73,75,1)), color-stop(70%,rgba(78,73,75,1)), color-stop(71%,rgba(78,73,76,0)), color-stop(72%,rgba(77,72,76,0)), color-stop(100%,rgba(24,19,25,0)));
              background: -webkit-radial-gradient(center, ellipse cover,  rgba(24,19,25,0) 0%,rgba(53,52,51,0) 34%,rgba(55,54,52,1) 36%,rgba(56,55,53,1) 37%,rgba(68,67,66,1) 38%,rgba(56,55,53,1) 39%,rgba(68,67,66,1) 40%,rgba(56,55,53,1) 41%,rgba(87,77,76,1) 42%,rgba(87,77,76,1) 44%,rgba(113,110,103,1) 46%,rgba(113,110,103,1) 48%,rgba(113,98,93,1) 49%,rgba(113,98,93,1) 51%,rgba(122,115,105,1) 52%,rgba(113,98,93,1) 53%,rgba(113,98,93,1) 54%,rgba(122,115,105,1) 55%,rgba(106,99,89,1) 56%,rgba(106,99,89,1) 58%,rgba(79,76,76,0) 60%,rgba(65,64,70,1) 61%,rgba(65,64,70,1) 62%,rgba(90,85,89,1) 63%,rgba(78,74,73,1) 65%,rgba(78,73,74,1) 67%,rgba(78,73,74,0) 68%,rgba(78,73,75,1) 69%,rgba(78,73,75,1) 70%,rgba(78,73,76,0) 71%,rgba(77,72,76,0) 72%,rgba(24,19,25,0) 100%);
              background: -o-radial-gradient(center, ellipse cover,  rgba(24,19,25,0) 0%,rgba(53,52,51,0) 34%,rgba(55,54,52,1) 36%,rgba(56,55,53,1) 37%,rgba(68,67,66,1) 38%,rgba(56,55,53,1) 39%,rgba(68,67,66,1) 40%,rgba(56,55,53,1) 41%,rgba(87,77,76,1) 42%,rgba(87,77,76,1) 44%,rgba(113,110,103,1) 46%,rgba(113,110,103,1) 48%,rgba(113,98,93,1) 49%,rgba(113,98,93,1) 51%,rgba(122,115,105,1) 52%,rgba(113,98,93,1) 53%,rgba(113,98,93,1) 54%,rgba(122,115,105,1) 55%,rgba(106,99,89,1) 56%,rgba(106,99,89,1) 58%,rgba(79,76,76,0) 60%,rgba(65,64,70,1) 61%,rgba(65,64,70,1) 62%,rgba(90,85,89,1) 63%,rgba(78,74,73,1) 65%,rgba(78,73,74,1) 67%,rgba(78,73,74,0) 68%,rgba(78,73,75,1) 69%,rgba(78,73,75,1) 70%,rgba(78,73,76,0) 71%,rgba(77,72,76,0) 72%,rgba(24,19,25,0) 100%);
              background: -ms-radial-gradient(center, ellipse cover,  rgba(24,19,25,0) 0%,rgba(53,52,51,0) 34%,rgba(55,54,52,1) 36%,rgba(56,55,53,1) 37%,rgba(68,67,66,1) 38%,rgba(56,55,53,1) 39%,rgba(68,67,66,1) 40%,rgba(56,55,53,1) 41%,rgba(87,77,76,1) 42%,rgba(87,77,76,1) 44%,rgba(113,110,103,1) 46%,rgba(113,110,103,1) 48%,rgba(113,98,93,1) 49%,rgba(113,98,93,1) 51%,rgba(122,115,105,1) 52%,rgba(113,98,93,1) 53%,rgba(113,98,93,1) 54%,rgba(122,115,105,1) 55%,rgba(106,99,89,1) 56%,rgba(106,99,89,1) 58%,rgba(79,76,76,0) 60%,rgba(65,64,70,1) 61%,rgba(65,64,70,1) 62%,rgba(90,85,89,1) 63%,rgba(78,74,73,1) 65%,rgba(78,73,74,1) 67%,rgba(78,73,74,0) 68%,rgba(78,73,75,1) 69%,rgba(78,73,75,1) 70%,rgba(78,73,76,0) 71%,rgba(77,72,76,0) 72%,rgba(24,19,25,0) 100%);
              background: radial-gradient(ellipse at center,  rgba(24,19,25,0) 0%,rgba(53,52,51,0) 34%,rgba(55,54,52,1) 36%,rgba(56,55,53,1) 37%,rgba(68,67,66,1) 38%,rgba(56,55,53,1) 39%,rgba(68,67,66,1) 40%,rgba(56,55,53,1) 41%,rgba(87,77,76,1) 42%,rgba(87,77,76,1) 44%,rgba(113,110,103,1) 46%,rgba(113,110,103,1) 48%,rgba(113,98,93,1) 49%,rgba(113,98,93,1) 51%,rgba(122,115,105,1) 52%,rgba(113,98,93,1) 53%,rgba(113,98,93,1) 54%,rgba(122,115,105,1) 55%,rgba(106,99,89,1) 56%,rgba(106,99,89,1) 58%,rgba(79,76,76,0) 60%,rgba(65,64,70,1) 61%,rgba(65,64,70,1) 62%,rgba(90,85,89,1) 63%,rgba(78,74,73,1) 65%,rgba(78,73,74,1) 67%,rgba(78,73,74,0) 68%,rgba(78,73,75,1) 69%,rgba(78,73,75,1) 70%,rgba(78,73,76,0) 71%,rgba(77,72,76,0) 72%,rgba(24,19,25,0) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00181319', endColorstr='#00181319',GradientType=1 );
    
              transform: rotateX(79deg) rotateY(-16deg);
            }

            .top, .bottom {
              background: green;
              width: 180px;
              height: 180px;
              position: absolute;
              top: 110px;
              left: 110px;
              z-index: 1;
              border-radius: 50%;

              background: -moz-radial-gradient(top, circle cover,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 40%, rgba(0,0,0,1) 100%), -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 62%, rgba(0,0,0,1) 100%), -moz-linear-gradient(bottom,  rgba(131,129,117,1) 0%, rgba(131,129,117,1) 3%, rgba(150,144,130,1) 5%, rgba(150,144,130,1) 9%, rgba(190,177,145,1) 20%, rgba(190,177,145,1) 32%, rgba(202,176,153,1) 35%, rgba(202,176,153,1) 47%, rgba(205,186,156,1) 48%, rgba(205,186,156,1) 49%, rgba(211,184,157,1) 51%, rgba(211,184,157,1) 59%, rgba(218,188,162,1) 61%, rgba(218,188,162,1) 63%, rgba(208,180,158,1) 64%, rgba(208,180,158,1) 68%, rgba(213,181,143,1) 74%, rgba(213,181,143,1) 78%, rgba(221,203,157,1) 83%, rgba(221,203,157,1) 85%, rgba(212,203,174,1) 90%, rgba(212,203,174,1) 100%);
              background: -webkit-radial-gradient(top, circle cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 40%,rgba(0,0,0,1) 100%), -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 62%,rgba(0,0,0,1) 100%), -webkit-linear-gradient(bottom,  rgba(131,129,117,1) 0%,rgba(131,129,117,1) 3%,rgba(150,144,130,1) 5%,rgba(150,144,130,1) 9%,rgba(190,177,145,1) 20%,rgba(190,177,145,1) 32%,rgba(202,176,153,1) 35%,rgba(202,176,153,1) 47%,rgba(205,186,156,1) 48%,rgba(205,186,156,1) 49%,rgba(211,184,157,1) 51%,rgba(211,184,157,1) 59%,rgba(218,188,162,1) 61%,rgba(218,188,162,1) 63%,rgba(208,180,158,1) 64%,rgba(208,180,158,1) 68%,rgba(213,181,143,1) 74%,rgba(213,181,143,1) 78%,rgba(221,203,157,1) 83%,rgba(221,203,157,1) 85%,rgba(212,203,174,1) 90%,rgba(212,203,174,1) 100%);
              background: -o-radial-gradient(top, circle cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 40%,rgba(0,0,0,1) 100%), -o-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 62%,rgba(0,0,0,1) 100%), -o-linear-gradient(bottom,  rgba(131,129,117,1) 0%,rgba(131,129,117,1) 3%,rgba(150,144,130,1) 5%,rgba(150,144,130,1) 9%,rgba(190,177,145,1) 20%,rgba(190,177,145,1) 32%,rgba(202,176,153,1) 35%,rgba(202,176,153,1) 47%,rgba(205,186,156,1) 48%,rgba(205,186,156,1) 49%,rgba(211,184,157,1) 51%,rgba(211,184,157,1) 59%,rgba(218,188,162,1) 61%,rgba(218,188,162,1) 63%,rgba(208,180,158,1) 64%,rgba(208,180,158,1) 68%,rgba(213,181,143,1) 74%,rgba(213,181,143,1) 78%,rgba(221,203,157,1) 83%,rgba(221,203,157,1) 85%,rgba(212,203,174,1) 90%,rgba(212,203,174,1) 100%);
              background: -ms-radial-gradient(top, circle cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 40%,rgba(0,0,0,1) 100%), -ms-radial-gradient(center, circle cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 62%,rgba(0,0,0,1) 100%), -ms-linear-gradient(bottom,  rgba(131,129,117,1) 0%,rgba(131,129,117,1) 3%,rgba(150,144,130,1) 5%,rgba(150,144,130,1) 9%,rgba(190,177,145,1) 20%,rgba(190,177,145,1) 32%,rgba(202,176,153,1) 35%,rgba(202,176,153,1) 47%,rgba(205,186,156,1) 48%,rgba(205,186,156,1) 49%,rgba(211,184,157,1) 51%,rgba(211,184,157,1) 59%,rgba(218,188,162,1) 61%,rgba(218,188,162,1) 63%,rgba(208,180,158,1) 64%,rgba(208,180,158,1) 68%,rgba(213,181,143,1) 74%,rgba(213,181,143,1) 78%,rgba(221,203,157,1) 83%,rgba(221,203,157,1) 85%,rgba(212,203,174,1) 90%,rgba(212,203,174,1) 100%);
              background: radial-gradient(circle at top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 40%,rgba(0,0,0,1) 100%), radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 62%,rgba(0,0,0,1) 100%), linear-gradient(to top,  rgba(131,129,117,1) 0%,rgba(131,129,117,1) 3%,rgba(150,144,130,1) 5%,rgba(150,144,130,1) 9%,rgba(190,177,145,1) 20%,rgba(190,177,145,1) 32%,rgba(202,176,153,1) 35%,rgba(202,176,153,1) 47%,rgba(205,186,156,1) 48%,rgba(205,186,156,1) 49%,rgba(211,184,157,1) 51%,rgba(211,184,157,1) 59%,rgba(218,188,162,1) 61%,rgba(218,188,162,1) 63%,rgba(208,180,158,1) 64%,rgba(208,180,158,1) 68%,rgba(213,181,143,1) 74%,rgba(213,181,143,1) 78%,rgba(221,203,157,1) 83%,rgba(221,203,157,1) 85%,rgba(212,203,174,1) 90%,rgba(212,203,174,1) 100%);
            }
    
            .top {
              clip: rect(0px, 180px, 90px, 0px);
            }
    
            .bottom {
              clip: rect(90px, 180px, 180px, 0px);
            }
        }
        
        &:hover .text__planet {
            opacity: 1;
        }
    }
}