.modal {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  opacity: 0;
  transition: all .2s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;

  &.active {
      opacity: 1;
      visibility: visible;
  }
}