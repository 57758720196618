@import '../../../assets/styles/global.scss';

.info {
    position: relative;
    color: white;
    z-index: 5;
    opacity: 0;
    visibility: visible;

    transition: all 1.5s ease-in-out;
    margin-left: 7rem;

    @extend %showText;

    @media screen and (max-width: 1050px) {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0;
    }

    .text__content {
      @media screen and (max-width: 1050px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 430px;
      }

      @media screen and (max-width: 550px) {
        max-width: 260px;
      }
    }  
}

.text__me {
    font-size: 7.2rem;
    font-weight: 500;
    line-height: 1;

  @media screen and (max-width: 1050px) {
    font-size: 4rem;
  }
}

.glitch {
    position: relative;
    color: white;
    margin: 0 auto;
    width: 400px;

    &:after {
      animation: glitch-animation 8s infinite linear alternate-reverse;
      background: black;
      clip: rect( 0, 900px, 0, 0 );
      color: white;
      content: attr( data-text );
      left: 2px;
      overflow: hidden;
      position: absolute;
      text-shadow: -1px 0 red;
      top: 12px;
    }

    &:before {
      animation: glitch-animation-2 9s infinite linear alternate-reverse;
      background: black;
      clip: rect( 0, 900px, 0, 0 );
      color: white;
      content: attr( data-text );
      left: -2px;
      overflow: hidden;
      position: absolute;
      text-shadow: 1px 0 blue;
      top: 12px;
    }

    @keyframes glitch-animation {
      0% {
        clip: rect(42px, 9999px, 44px, 0);
      }
      5% {
        clip: rect(12px, 9999px, 59px, 0);
      }
      10% {
        clip: rect(48px, 9999px, 29px, 0);
      }
      15.0% {
        clip: rect(42px, 9999px, 73px, 0);
      }
      20% {
        clip: rect(63px, 9999px, 27px, 0);
      }
      25% {
        clip: rect(34px, 9999px, 55px, 0);
      }
      30.0% {
        clip: rect(86px, 9999px, 73px, 0);
      }
      35% {
        clip: rect(20px, 9999px, 20px, 0);
      }
      40% {
        clip: rect(26px, 9999px, 60px, 0);
      }
      45% {
        clip: rect(25px, 9999px, 66px, 0);
      }
      50% {
        clip: rect(57px, 9999px, 98px, 0);
      }
      55.0% {
        clip: rect(5px, 9999px, 46px, 0);
      }
      60.0% {
        clip: rect(82px, 9999px, 31px, 0);
      }
      65% {
        clip: rect(54px, 9999px, 27px, 0);
      }
      70% {
        clip: rect(28px, 9999px, 99px, 0);
      }
      75% {
        clip: rect(45px, 9999px, 69px, 0);
      }
      80% {
        clip: rect(23px, 9999px, 85px, 0);
      }
      85.0% {
        clip: rect(54px, 9999px, 84px, 0);
      }
      90% {
        clip: rect(45px, 9999px, 47px, 0);
      }
      95% {
        clip: rect(37px, 9999px, 20px, 0);
      }
      100% {
        clip: rect(4px, 9999px, 91px, 0);
      }
    }

    @keyframes glitch-animation-2 {
      0% {
        clip: rect(65px, 9999px, 100px, 0);
      }
      5% {
        clip: rect(52px, 9999px, 74px, 0);
      }
      10% {
        clip: rect(79px, 9999px, 85px, 0);
      }
      15.0% {
        clip: rect(75px, 9999px, 5px, 0);
      }
      20% {
        clip: rect(67px, 9999px, 61px, 0);
      }
      25% {
        clip: rect(14px, 9999px, 79px, 0);
      }
      30.0% {
        clip: rect(1px, 9999px, 66px, 0);
      }
      35% {
        clip: rect(86px, 9999px, 30px, 0);
      }
      40% {
        clip: rect(23px, 9999px, 98px, 0);
      }
      45% {
        clip: rect(85px, 9999px, 72px, 0);
      }
      50% {
        clip: rect(71px, 9999px, 75px, 0);
      }
      55.0% {
        clip: rect(2px, 9999px, 48px, 0);
      }
      60.0% {
        clip: rect(30px, 9999px, 16px, 0);
      }
      65% {
        clip: rect(59px, 9999px, 50px, 0);
      }
      70% {
        clip: rect(41px, 9999px, 62px, 0);
      }
      75% {
        clip: rect(2px, 9999px, 82px, 0);
      }
      80% {
        clip: rect(47px, 9999px, 73px, 0);
      }
      85.0% {
        clip: rect(3px, 9999px, 27px, 0);
      }
      90% {
        clip: rect(26px, 9999px, 55px, 0);
      }
      95% {
        clip: rect(42px, 9999px, 97px, 0);
      }
      100% {
        clip: rect(38px, 9999px, 49px, 0);
      }
    }
}

.prof__me {
    margin-top: 2rem;
    margin-bottom: 5rem;
    color: #575757;

    @media screen and (max-width: 1050px) {
      margin-top: 2rem;
      text-align: center;
    }
}

.send-link {

    animation: btnBols 1s linear alternate infinite;
    
    @keyframes btnBols {
        0% {
            transform: translate3d(0, -10px, 0);
        }
        50% {
            transform: translate3d(0, 0px, 0);
        }
        100% {
            transform: translate3d(0, 10px, 0);
        }
    }
    
    .send-btn {
        position: relative;

        color: #fff;
        background: none;
        font-weight: 500;
        padding: 8px 21px;
        text-decoration: none;
        border: 2px solid #08FDD8;
        z-index: 1;

        transition: color .2s linear;
        &:hover {
            color: #000;

            &:after,
            &:before {
                width: calc(50% + 2px);
            }
        }

        &:after,
        &:before {
            display: block;
            content: "";

            position: absolute;
            top: -2px;
            height: 40px;
            width: 0px;
            z-index: -1;
            background-color: #08FDD8;

            transition: width .2s linear;
        }
        &:after {
            left: calc( 50% - 1px);
        }

        &:before {
            right: calc(50% - 1px);
        }
    }
}
