@import '../../../assets/styles/global.scss';
@import '../../../assets/styles/colors.scss';

.container {

    @extend %pageContent;
    width: 100%;

    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 2px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $main;
        border-radius: 5px;
    }

    .pageContent {
        margin: 0;
        padding: 0;
        margin-left: 3px;

        @media screen and (max-width: 1050px) {
            padding-bottom: 10rem;
        }

        .link-add-team {
            color: #08FDD8;
            cursor: pointer;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }
}