.container {
	position: relative;
	z-index: 10;
	height: 100vh;
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	@media screen and (max-width: 1050px) {
		overflow-y: scroll;
		overflow-x: hidden;
		height: 100vh;
		width: 100%;
		// margin-bottom: 7rem;
	}

	@media screen and (max-width: 1050px) {
		justify-content: center;
		align-items: center;
		margin: 0;
	}

	.content {
		opacity: 0;
		transition: all 2s linear;
		color: #fff;
		&.active {
			opacity: 1;
		}
	}
}
