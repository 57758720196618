.span-text {
  color: #fff;
  font-weight: 700;

  @media screen and (max-width: 1050px) {
    margin-top: 3rem;
  }
  
  .m-r {
      margin-right: 2rem;
  }
  
  & span {
    display: inline-block;
    position: relative;
    transform-style: preserve-3d;
    perspective: 500;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
  } 

  & span::before,
  & span::after {
    display: none;
    position: absolute;
    top: 0;
    left: -1px;
    transform-origin: left top;
    transition: all ease-out 0.3s;
    content: attr(data-text);
  }

  & span::before {
    z-index: 1;
    color: rgba(0,0,0,0.2);
    transform: scale(1.1, 1) skew(0deg, 20deg);
  }

  & span::after {
    z-index: 2;
    color: #08FDD8;
    text-shadow: -1px 0 1px #684da3, 1px 0 1px rgba(0,0,0,0.8);
    transform: rotateY(-40deg);
  }

  & span:hover::before {
    transform: scale(1.1, 1) skew(0deg, 5deg);
  }

  & span:hover::after {
    transform: rotateY(-10deg);
  }

  & span + span {
    margin-left: .8rem;
  }

  @media (min-width: 20em) {
    & {
      font-size: 2rem;
    }
    & span::before,
    & span::after {
      display: block;
    }
  }
  @media (min-width: 30em) {
    & {
      font-size: 2.4rem;
    }
  }
  @media (min-width: 40em) {
    & {
      font-size: 2.4rem;
    }
  }
  @media (min-width: 60em) {
    & {
      font-size: 4.4rem;
    }
  }
}