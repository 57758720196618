@tailwind base;
@tailwind components;
@tailwind utilities;
@import './assets/styles/reset.css';
@import './assets/styles/colors.scss';

html {
  font-size: 62.5%;
}

body {
  background-color: $black;
  // font-family: 'Aldrich';
  font-family: -apple-system, BlinkMacSystemFont, Segoe\ UI, Roboto, Helvetica\ Neue, Arial, Noto\ Sans, sans-serif, Gilroy-Medium, Gilroy-Bold;
  color: $white;
  font-size: 1.4rem;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
  position: fixed;

  @media screen and (max-width: 1050px) {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

