@import '../../../assets/styles/colors.scss';

.description {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 50%;
    background-color: rgba($color: $black, $alpha: 0.9);
    transition: all .4s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform: translateY(-100%);
    text-align: center;

    @media screen and (max-width: 600px) {
        font-size: 12px;
    }

    h3 {
        color: $main;
        margin-top: 1rem;
    }

    a {
        text-decoration: underline;
    }
}