@import '../../../assets/styles/global.scss';
@import '../../../assets/styles/colors.scss';

.workItem {
    margin: 1rem;
    overflow: hidden;
    cursor: pointer;
    border-radius: 5px;

    .img {
        max-width: 550px;
        background-repeat: no-repeat;
        background-size: cover;
        width: 550px;
        height: 260px;
        background-position: center;
        border-radius: 5px;
        transition: all .4s cubic-bezier(0.645, 0.045, 0.355, 1);

        @media screen and (min-width: 1070px) {
            &:hover {
                transform: scale(1.2);
    
                dd {
                    transform: translateY(0);
                }
            }
        }


        @media screen and (max-width: 600px) {
            width: 350px;
            height: 162px;
        }

        @media screen and (max-width: 380px) {
            width: 290px;
        }
    }

    @media screen and (max-width: 600px) {
        margin: 0;
        margin-bottom: 1rem;
    }
}