.scy {
  opacity: 0.3;
    a:visited {
        text-decoration:none;
        color:#fcfcfc;
    }
    
    @keyframes move-twink-back {
        from {background-position:0 0;}
        to {background-position:-10000px 5000px;}
    }
    @-webkit-keyframes move-twink-back {
        from {background-position:0 0;}
        to {background-position:-10000px 5000px;}
    }
    @-moz-keyframes move-twink-back {
        from {background-position:0 0;}
        to {background-position:-10000px 5000px;}
    }
    @-ms-keyframes move-twink-back {
        from {background-position:0 0;}
        to {background-position:-10000px 5000px;}
    }
    
    .stars, .twinkling {
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      width:100%;
      height:100%;
      display:block;
      z-index:1;
    }
    
    .stars {
      background:#000 url('../../../assets/images/stars-skay/stars.jpg');
    }
    
    .twinkling{
      background:transparent url('../../../assets/images/stars-skay/twinkling.png');
    
      -moz-animation:move-twink-back 500s linear infinite;
      -ms-animation:move-twink-back 500s linear infinite;
      -o-animation:move-twink-back 500s linear infinite;
      -webkit-animation:move-twink-back 500s linear infinite;
      animation:move-twink-back 500s linear infinite;
    }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes glitter {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  25% {
    transform: scale(1.6);
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    opacity: 0;
  }
  75% {
    transform: scale(1.6);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

.space {
  position: absolute;
  height: 400vh;
  width: 400vw;
  left: -150vw;
  top: -150vh;
  overflow: hidden;
  background: #000;
  transform-origin: 50% 50%;
  animation: spin 500s linear 0s infinite normal;
}

.star {
  position: absolute;
  width: 1px;
  height: 1px;
  background: #999;
  opacity: 0;
  animation: glitter 8s ease-in 0s infinite normal;
}
